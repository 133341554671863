// angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { map } from 'rxjs/operators';

// packages
import { Observable } from 'rxjs';
import { EquipmentService } from '../../api/equipment.service';

// local
import { LoginService } from '../../api/login.service';
import { AuthenticationService } from '../authentication.service';

/**
 * Checking user permissions to make sure wrapper show menu sections correctly
 */
@Injectable({
	providedIn: 'root'
})
export class AuthenticationGuard  {


	constructor(
		private _loginService: LoginService,
		public _router: Router,
		private auth: AuthenticationService,
		private _equipmentService: EquipmentService,
	) {}


	canActivate(route: ActivatedRouteSnapshot):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {

		const isUserLogged = this._loginService.isLogged();
		// console.log(`iul --> `, isUserLogged);
		const authUser = JSON.parse(JSON.stringify(this.auth.user));
		// console.log(`au --> `, authUser);
		if (authUser?.permissions) {
			// console.log(`a__1 hp`);
			if (!isUserLogged) this._router.navigate(['']);
			return isUserLogged;
		} else { // getting permissions from backend
			// console.log(`a__2 dnhp`);
			return this._equipmentService.getGeneralOptions()
				.pipe(map((data) => {
					// console.log(`a__3 mr`);
					authUser.permissions = data.permissions;
					// console.log(`auth user = user; 4`);
					this.auth.user = authUser;
					if (!isUserLogged) this._router.navigate(['']);
					return isUserLogged;
				}));
		}
	}

}
