import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { LoginService } from '../api/login.service';
import { SnackbarService } from '../snackbar.service';
import { NotificationService } from '../api/notification.service';
import { USER_STORAGE_KEYS } from './authentication.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
	constructor(
		private _loginService: LoginService,
		private _router: Router,
		private _snackbar: SnackbarService,
		private notificationsService: NotificationService
	) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Insert request headers
		const authorizedRequest = req.clone({
			setHeaders: {
				Authorization: 'Bearer ' + localStorage.getItem(USER_STORAGE_KEYS.SESSION_TOKEN),
				Offset: String(new Date().getTimezoneOffset() / 60)
			},
		});

		return next.handle(authorizedRequest).pipe( map( (event: HttpEvent<any>) => {
			// Success Response
			if (event instanceof HttpResponse) {
				// Here would be the code to do something if response is 200
				// console.log( event );  
				this.notificationsService.unreadNotifications.next(event.body.unread_notifications);  

				const loggedUser = JSON.parse(localStorage.getItem(USER_STORAGE_KEYS.SESSION_USER));
				if ( loggedUser && loggedUser.id && loggedUser.isActive === false ) {
					console.warn('Logged user not currently active');
					this._snackbar.show('Logged user not currently active', 'close', 'warning');
					this._router.navigate(['login']);
					this._loginService.user$.next(null);
					localStorage.clear();
					sessionStorage.clear();
				}

			}
			return event;
		}), catchError((err: any) => {
			// if (err instanceof HttpErrorResponse) {

			// 	if (err.status === 401) {
			// 		this.loginService.logOut();
			// 		return throwError(err);
			// 	} else if (err.status === 400) {

			// 		if (this.refreshTokenInProgress) {
			// 			// If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
			// 			// which means the new token is ready and we can retry the request again
			// 			return this.refreshTokenSubject.pipe(
			// 			filter(result => result !== null),
			// 			take(1),
			// 			switchMap(() => next.handle(this.addAuthenticationToken(req)))
			// 			);
			// 		} else {
			// 			this.refreshTokenInProgress = true;

			// 			// Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
			// 			this.refreshTokenSubject.next(null);

			// 			return this.loginService.refreshSession().pipe(
			// 				switchMap((success: boolean) => {
			// 					this.refreshTokenSubject.next(success);
			// 					return next.handle(this.addAuthenticationToken(req));
			// 				}),
			// 				// When the call to refreshToken completes we reset the refreshTokenInProgress to false
			// 				// for the next time the token needs to be refreshed
			// 				finalize(() => (this.refreshTokenInProgress = false))
			// 			);
			// 		}
			// 	} else {
			// 		return throwError(err);
			// 	}
			// }

			return throwError(err);

		})) as Observable<HttpEvent<any>>;
	}

	private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
		return request.clone({
			setHeaders: {
				Authorization: 'Bearer ' + localStorage.getItem(USER_STORAGE_KEYS.SESSION_TOKEN),
			},
		});
	}

}
