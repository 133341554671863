import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { LoginService } from './_core/services/api/login.service';
import { User } from './_core/models';
import { Title } from '@angular/platform-browser';
import { USER_STORAGE_KEYS } from './_core/services/auth/authentication.service';

@Component({
	selector: 'app-root',
	template: '<router-outlet></router-outlet>',
	styleUrls: []
})
export class AppComponent implements OnInit {
	title = 'Flight Ninja';
	user = new User();
	isResetToken = false;


	constructor(
		private _loginService: LoginService,
		private _router: Router,
		private titleService: Title
	) {
		_router.events.forEach((event) => {
			if (event instanceof NavigationStart) {
				// console.log(`event --> `, event);
				if (event.url) {
					this.isResetToken = false;
					const url = String(event.url);
					// console.log(`url --> `, url);
					let title = this.title;
					if (url.indexOf('app/users') !== -1) {
						title += ` | Users`;
					}
					else if (url.indexOf('app/schedule/events') !== -1) {
						title += ` | Events`;
					}
					else if (url.indexOf('app/schedule') !== -1) {
						title += ` | Schedule`;
					}
					else if (url.indexOf('app/courses') !== -1) {
						title += ` | Courses`;
					}
					else if (url.indexOf('app/grading') !== -1) {
						title += ` | Grading`;
					}
					else if (url.indexOf('app/equipment') !== -1) {
						title += ` | Equipment`;
					}
					else if (url.indexOf('app/credentials') !== -1) {
						title += ` | Credentials`;
					}
					else if (url.indexOf('app/groups') !== -1) {
						title += ` | Groups`;
					}
					else if (url.indexOf('app/FTA') !== -1) {
						title += ` | FTA`;
					}
					else if (url.indexOf('app/settings') !== -1) {
						title += ` | Settings`;
					}
					else if (url.indexOf('logbook') !== -1) {
						title += ` | Logbook`;
					}
					else if (url.indexOf('readiness') !== -1) {
						title += ` | Readiness`;
					}
					else if (url.indexOf('dashboard') !== -1) {
						title += ` | Dashboard`;
					}
					else if (url.indexOf('sadashboard') !== -1) {
						title += ` | SA-Dashboard`;
					}
					else if (url.indexOf('CR-dash') !== -1) {
						title += ` | CR-Dashboard`;
					}
					else if (url.indexOf('attendance') !== -1) {
						title += ` | Attendance-Dashboard`;
					}
					else if (url.indexOf('reset-token') !== -1) {
						this.isResetToken = true;
					} else if (url.indexOf('enrollment-questionnaire') !== -1) {
						this.isResetToken = true;
					}
					this.titleService.setTitle(title);
				}
				// console.log(`isResetToken`, this.isResetToken);
			}
		});
	}


	ngOnInit() {
		setTimeout(() => {
			this.getUserInfo();
		}, 300);
	}

	getUserInfo() {
		const storedUser = JSON.parse(localStorage.getItem(USER_STORAGE_KEYS.SESSION_USER)) || null;
		const userId = localStorage.getItem(USER_STORAGE_KEYS.USER_ID) || null;
		const token = localStorage.getItem(USER_STORAGE_KEYS.SESSION_TOKEN) || null;
		// console.log(`storedUser --> `, storedUser);
		// console.log(`userId     --> `, userId);
		// console.log(`token      --> `, token);

		if (storedUser && storedUser.id && userId && token) {
			// this._usersService.getSingleUser(storedUser.id, true).subscribe((response: User) => {
			// 	this.user = response;
			// 	this._loginService.user$.next(response);
			// }, (err) => {
			// 	if ( err && err.error && err.error.message && err.error.message === 'Unauthorized' ) {
			// 		console.error(`Unauthorized`);
			// 		this._router.navigate(['login']);
			// 		this._loginService.user$.next(null);
			// 		localStorage.clear();
			// 		sessionStorage.clear();
			// 		this._loginService.logOut();
			// 	} else {
			// 		this._snackbarService.errorHandler('getUser()', err);
			// 	}
			// });
		}

		/**
		 * If URL is not reset-token
		 */
		else if (!this.isResetToken) {
			console.error(`Unauthorized`);
			this._router.navigate(['login']);
			this._loginService.user$.next(null);
		}
	}

}
