import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LastActivityTrackerService } from './last-activity-tracker.service';

@Injectable()
export class LastActivityTrackerInterceptorService implements HttpInterceptor {
	constructor(
		private _lastActivityTrackerService: LastActivityTrackerService,
	) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this._lastActivityTrackerService.track()

		return next.handle(req);
	}
}
