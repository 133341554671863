import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
	handleError(err: any): void {
		console.log(`%c GlobalErrorHandler! `, `background: #111; color: orange`);
		console.error(err);
		console.log(`err.message --> `, err.message);
		const chunkFailedMessage = /Loading chunk [\d]+ failed/;
		if (
			chunkFailedMessage.test(err?.message) ||
			err?.message.includes(`/options: 500 Internal Server Error","error":{"message":"Trying to get property 'role_id' of non-object"`)
		) {
			setTimeout(() => {
				localStorage.clear();
				window.location.reload();
			}, 1000);
		}
	}
}
