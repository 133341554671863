import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// packages
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenuModule } from '@angular/material/menu';

// local
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpInterceptorService } from './_core/services/auth/http-interceptor.service';
import { LastActivityTrackerInterceptorService } from './_core/services/auth/last-activity-tracker-interceptor.service';
import { FormDataService } from './_core/services/api/form-data.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DatePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { GlobalErrorHandler } from './global-error-handler';
import { GetObjectNamePipe } from './_core/pipes/get-object-name.pipe';
import { PermissionPipe } from './_core/shared/pipes/permission.pipe';
import { ShowEventCancelButtonPipe } from './_core/shared/pipes/show-event-cancel-button.pipe';
import { GetArrayNamesPipe } from './_core/pipes/get-array-names.pipe';

//equipment calendar
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatTableModule } from '@angular/material/table';
import { provideNgxMask } from 'ngx-mask';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MatDialogModule,
		HttpClientModule,
		FormsModule,
		MatMenuModule,
		ReactiveFormsModule,
		MatSnackBarModule,
		MatSelectModule,
		MatAutocompleteModule,
		InfiniteScrollModule,
		MatInputModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatChipsModule,
		MatTableModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		NgApexchartsModule
	],
	providers: [
		HttpClient,
		DatePipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorService,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LastActivityTrackerInterceptorService,
			multi: true
		},
		FormDataService,
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		GetObjectNamePipe,
		GetArrayNamesPipe,
		PermissionPipe,
		ShowEventCancelButtonPipe,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
